import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export const scrollToHash = (hash: string, elOffset = 30, pageOffset = 20) => {
  const el = document.querySelector(hash)
  const scrollWrapperEl = document.querySelector('#scroll-wrapper')
  if (el) {
    if (scrollWrapperEl) {
      scrollWrapperEl.scrollTo({
        top: el.getBoundingClientRect().top + scrollWrapperEl.scrollTop - elOffset,
        behavior: 'smooth',
      })
    }
    window.scrollTo({ top: el.getBoundingClientRect().top + window.pageYOffset - pageOffset, behavior: 'smooth' })
  }
}

export const ScrollToHash = ({ elOffset = 30, pageOffset = 60 }: { elOffset?: number; pageOffset?: number }) => {
  const { hash, pathname, search } = useLocation()
  // const navigate = useNavigate()

  useEffect(() => {
    if (hash) {
      scrollToHash(hash, elOffset, pageOffset)
      const newUrl = `${pathname}${search}`
      // navigate(newUrl, { replace: true })
      window.history.replaceState(null, '', newUrl)
    }
  }, [hash, pathname])

  return null
}
