import cn from 'classnames'
import css from './index.module.scss'
import { MyLink } from '@/webapp/src/pages/other/PromoPage/components/MyLink.js'

export const Button1 = ({
  children,
  onClick,
  href,
  className,
  variant,
  disabled,
  loading,
  type,
}: {
  children: React.ReactNode
  onClick?: () => any
  href?: string
  className?: string
  variant: 'dashed' | 'primary'
  disabled?: boolean
  loading?: boolean
  type?: 'button' | 'submit'
}) => {
  href = onClick ? undefined : type ? undefined : href || '#'
  disabled = disabled || loading
  const classNameHere = cn({
    [css.button]: true,
    [className || '']: !!className,
    [css[`variant-${variant}`]]: true,
    [css.disabled]: !!disabled,
  })
  if (!href && !onClick && !type) {
    throw new Error('Button1: onClick or href is required')
  }
  if (href) {
    if (href.startsWith('#')) {
      return (
        <MyLink className={classNameHere} to={href}>
          {children}
        </MyLink>
      )
    } else {
      return (
        <a className={classNameHere} href={href}>
          {children}
        </a>
      )
    }
  } else {
    return (
      <button className={classNameHere} onClick={onClick}>
        {children}
      </button>
    )
  }
}
