import { z } from 'zod'

export const langs = ['en', 'ru', 'de', 'it'] as const
export type Lang = (typeof langs)[number]
export const zLangRequired = z.enum(langs)
export const zLangsRequired = zLangRequired.array()
const langNames = {
  en: 'English',
  ru: 'Russian',
  de: 'German',
  it: 'Italian',
}
export const langOptions = langs.map((lang) => ({
  value: lang,
  label: langNames[lang],
}))
