// @index('../assets/icons/*.{png,jpg,svg}', (f, _) => `import ${_.camelCase(f.name).replace('_', '')} from '${f.path}${f.ext}?react'`)
import chevronDown from '../assets/icons/chevron_down.svg?react'
import closeTimes from '../assets/icons/close_times.svg?react'
import menuBurger from '../assets/icons/menu_burger.svg?react'
// @endindex

export const iconsSources = {
  // @index('../assets/icons/*.{png,jpg,svg}', (f, _) => `${_.camelCase(f.name).replace('_', '')},`)
  chevronDown,
  closeTimes,
  menuBurger,
  // @endindex
}
