import cn from 'classnames'
import css from './index.module.scss'
import type { FormyInputPropsGeneral } from 'svag-formy/dist/utils.js'
import { useFormyField } from '@/webapp/src/lib/formy.js'

export const Textary1 = ({
  name,
  hint,
  label,
  placeholder,
  formy,
  initialHeight = 180,
  maxWidth,
}: FormyInputPropsGeneral & {
  name: string
  label?: string
  placeholder?: string
  hint?: React.ReactNode
  initialHeight?: number
  maxWidth?: string | number
}) => {
  const { error, value, invalid } = useFormyField({ formy, name })
  const disabled = formy.isSubmitting

  return (
    <div className={cn({ [css.field]: true, [css.disabled]: disabled })}>
      {label && (
        <label className={css.label} htmlFor={name}>
          {label}
        </label>
      )}
      <textarea
        style={{ height: initialHeight, maxWidth }}
        className={cn({
          [css.input]: true,
          [css.invalid]: invalid,
        })}
        placeholder={placeholder}
        onChange={(e) => {
          void formy.setFieldValue(name, e.target.value)
        }}
        onBlur={() => {
          void formy.setFieldTouched(name)
        }}
        value={value}
        name={name}
        id={name}
        disabled={disabled}
      />
      {invalid && <div className={css.error}>{error}</div>}
      {hint && <div className={css.hint}>{hint}</div>}
    </div>
  )
}
