import { zUpdateProjectForUserEndpointInput } from '@/general/src/project/routes/updateProjectForUser/input.js'
import type { ClientProjectForUser } from '@/general/src/project/utils.server.js'
import { Textfieldy, useFormy } from '@/webapp/src/lib/formy.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Button, Buttons, FormItems, LabeledValues, Segment } from '@/webapp/src/lib/uninty.components.js'

export const ProjectHeader = ({ project }: { project: ClientProjectForUser }) => {
  const updateProjectForUser = trpc.updateProjectForUser.useMutation()
  const trpcUtils = trpc.useUtils()
  const formy = useFormy({
    initialValues: {
      projectId: project.id,
      name: project.name,
    },
    validationSchema: zUpdateProjectForUserEndpointInput,
    onSubmit: async ({ valuesInput }) => {
      const res = await updateProjectForUser.mutateAsync({
        ...valuesInput,
      })
      trpcUtils.getProjectForUser.setData({ projectSn: res.project.sn }, res)
    },
    successMessage: 'Project updated',
  })
  return (
    <Segment title={`Project #${project.sn}`} size="m">
      <FormItems as="form" {...formy.formProps}>
        <LabeledValues items={[{ label: 'Speakers Count', value: project.speakersCount }]} />
        <Textfieldy label="Name" {...formy.getFieldProps('name')} />
        <Buttons>
          <Button {...formy.buttonProps} type="submit">
            Save
          </Button>
        </Buttons>
      </FormItems>
    </Segment>
  )
}
