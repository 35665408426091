import { scrollToHash } from '@/webapp/src/components/other/ScrollToHash/index.js'
import { Link, type LinkProps } from 'react-router-dom'

export const MyLink = (props: LinkProps) => {
  const { to, ...rest } = props
  if (to && typeof to === 'string' && to.startsWith('#')) {
    return (
      <a
        {...rest}
        href={to}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          rest.onClick?.(e)
          scrollToHash(to)
        }}
      />
    )
  } else {
    return <Link {...rest} to={to} />
  }
}
