import { useFormyField } from '@/webapp/src/lib/formy.js'
import cn from 'classnames'
import { format } from 'date-fns/format'
import type { FormyInputPropsGeneral } from 'svag-formy/dist/utils.js'
import css from './index.module.scss'

export const Inputy1 = ({
  name,
  label,
  formy,
  placeholder,
  maxWidth,
  hint,
  type = 'text',
}: FormyInputPropsGeneral & {
  name: string
  label?: string
  maxWidth?: number | string
  placeholder?: string
  hint?: string
  type?: 'text' | 'password' | 'date' | 'datetime-local'
}) => {
  const { error, value, invalid } = useFormyField({ formy, name })
  const disabled = formy.isSubmitting
  const currentInputValue = type === 'date' || type === 'datetime-local' ? format(value, "yyyy-MM-dd'T'HH:mm") : value

  return (
    <div className={cn({ [css.field]: true, [css.disabled]: disabled })}>
      {label && (
        <label className={css.label} htmlFor={name}>
          {label}
        </label>
      )}
      <input
        className={cn({
          [css.input]: true,
          [css.invalid]: invalid,
        })}
        placeholder={placeholder}
        style={{ maxWidth }}
        type={type}
        onChange={(e) => {
          const newValue = type === 'date' || type === 'datetime-local' ? new Date(e.target.value) : e.target.value
          void formy.setFieldValue(name, newValue)
        }}
        onBlur={() => {
          void formy.setFieldTouched(name)
        }}
        value={currentInputValue}
        name={name}
        id={name}
        disabled={disabled}
      />
      {invalid && <div className={css.error}>{error}</div>}
      {hint && <div className={css.hint}>{hint}</div>}
    </div>
  )
}
