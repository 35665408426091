/* eslint-disable no-irregular-whitespace */

// @index('../../../assets/images/promo/*.{png,jpg,svg}', (f, _) => {const upperFirst=(s)=>s.charAt(0).toUpperCase()+s.slice(1); return `import ${upperFirst(_.camelCase(f.name)).replace('_', '')} from '${f.path}${f.ext}?react'`})
import ArrowLeftDown from '../../../assets/images/promo/arrow-left-down.svg?react'
import ArrowRightDown from '../../../assets/images/promo/arrow-right-down.svg?react'
import ArrowRight from '../../../assets/images/promo/arrow-right.svg?react'
import Circle from '../../../assets/images/promo/circle.svg?react'
import De from '../../../assets/images/promo/de.svg?react'
import En from '../../../assets/images/promo/en.svg?react'
import Es from '../../../assets/images/promo/es.svg?react'
import It from '../../../assets/images/promo/it.svg?react'
import Pt from '../../../assets/images/promo/pt.svg?react'
import Ru from '../../../assets/images/promo/ru.svg?react'
import Scratch1 from '../../../assets/images/promo/scratch-1.svg?react'
import Scratch2 from '../../../assets/images/promo/scratch-2.svg?react'
import Scratch3 from '../../../assets/images/promo/scratch-3.svg?react'
import Scratch4 from '../../../assets/images/promo/scratch-4.svg?react'
import Tr from '../../../assets/images/promo/tr.svg?react'
// @endindex

import { zContactUsEndpointInput } from '@/backend/src/router/trpc/routes/contactUs/input.js'
import { useFormy } from '@/webapp/src/lib/formy.js'
import { withPageWrapper } from '@/webapp/src/lib/pageWrapper.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Icon } from '@/webapp/src/lib/uninty.components.js'
import { Button1 } from '@/webapp/src/pages/other/PromoPage/components/Button1/index.js'
import { Inputy1 } from '@/webapp/src/pages/other/PromoPage/components/Inputy1/index.js'
import { MyLink } from '@/webapp/src/pages/other/PromoPage/components/MyLink.js'
import { Textary1 } from '@/webapp/src/pages/other/PromoPage/components/Textary1/index.js'
import cn from 'classnames'
import _ from 'lodash'
import { useMemo, useState } from 'react'
import LogoColor from '../../../assets/images/other/logo-color.svg?react'
import LogoGray from '../../../assets/images/other/logo-gray.svg?react'
import css from './index.module.scss'

type Lang = 'en' | 'es' | 'de' | 'it' | 'pt' | 'tr' | 'ru'

type Video = {
  srcLang: Lang
  width: number
  height: number
  versions: Array<{ youtubeId: string; lang: Lang }>
}

const normalizeVideo = (video: Video) => {
  const ratio = video.height / video.width
  const distLangs = video.versions.map((v) => v.lang).filter((lang) => lang !== video.srcLang)
  return {
    ...video,
    ratio,
    distLangs,
  }
}

type NormalizedVideo = ReturnType<typeof normalizeVideo>

const YoutubeVideo = ({ video, lang, autoplay }: { video: NormalizedVideo; lang: Lang; autoplay?: boolean }) => {
  const version = video.versions.find((v) => v.lang === lang)
  return (
    <iframe
      width="100%"
      height="100%"
      src={`https://www.youtube.com/embed/${version?.youtubeId}?autoplay=${autoplay ? '1' : 0}`}
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      referrerPolicy="strict-origin-when-cross-origin"
      allowFullScreen
      className={css.video}
    ></iframe>
  )
}

const Flag = ({ lang, className }: { lang: Lang; className?: string }) => {
  const classNameHere: string = cn(className)
  switch (lang) {
    case 'en':
      return <En className={classNameHere} />
    case 'es':
      return <Es className={classNameHere} />
    case 'pt':
      return <Pt className={classNameHere} />
    case 'ru':
      return <Ru className={classNameHere} />
    case 'it':
      return <It className={classNameHere} />
    case 'de':
      return <De className={classNameHere} />
    case 'tr':
      return <Tr className={classNameHere} />
    default:
      return null
  }
}

const Menu = ({ onClick }: { onClick?: () => void }) => {
  return (
    <div className={css.menu}>
      <MyLink onClick={onClick} className={cn(css.menuItem)} to="#how">
        How it works?
      </MyLink>
      <MyLink onClick={onClick} className={cn(css.menuItem)} to="#samples">
        Samples
      </MyLink>
      <MyLink onClick={onClick} className={cn(css.menuItem)} to="#price">
        Price
      </MyLink>
      <MyLink onClick={onClick} className={cn(css.menuItem, css.special)} to="#contact">
        Get started for Free
      </MyLink>
    </div>
  )
}

const Header = () => {
  const [menuOpened, setMenuOpened] = useState(false)
  return (
    <div className={css.header}>
      <div className={cn(css.headerFixed, { [css.filled]: !!menuOpened })}>
        <div className={css.sectionWithPadding}>
          <div className={css.desktopHeaderContent}>
            <LogoColor className={css.logo} />
            <Menu />
          </div>
          <div className={css.mobileHeaderContent}>
            <LogoColor className={css.logo} />
            <button
              onClick={() => {
                setMenuOpened(!menuOpened)
              }}
              className={css.burgerButton}
            >
              <Icon className={css.burgerIcon} name={menuOpened ? 'closeTimes' : 'menuBurger'} />
            </button>
            {menuOpened && (
              <div className={css.mobileMenu}>
                <Menu
                  onClick={() => {
                    setMenuOpened(false)
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const FlagButton = ({ lang, active, onClick }: { lang: Lang; active: boolean; onClick: () => any }) => {
  return (
    <button className={cn(css.flagButton, { [css.active]: active })} onClick={onClick}>
      <Flag lang={lang} className={css.flag} />
    </button>
  )
}

const IntroSection = () => {
  const video = normalizeVideo({
    srcLang: 'en',
    width: 1280,
    height: 720,
    versions: [
      { youtubeId: 'ja1QQLS8788', lang: 'en' },
      { youtubeId: 'eX7XeLQ3p2U', lang: 'es' },
      { youtubeId: 'eX7XeLQ3p2U', lang: 'pt' },
      { youtubeId: 'eX7XeLQ3p2U', lang: 'ru' },
      { youtubeId: 'eX7XeLQ3p2U', lang: 'it' },
      { youtubeId: 'eX7XeLQ3p2U', lang: 'de' },
      { youtubeId: 'eX7XeLQ3p2U', lang: 'tr' },
    ],
  })
  const [activeLang, setActiveLang1] = useState(video.distLangs[0])
  const [autoplay, setAutoplay] = useState(false)
  const setActiveLang = (lang: Lang) => {
    setActiveLang1(lang)
    setAutoplay(true)
  }
  return (
    <div className={css.intro} id="intro">
      <div className={css.sectionWithPadding}>
        <div className={css.columns}>
          <div className={css.leftColumn}>
            <h1 className={css.sectionTitle}>
              <span className={css.withScratch}>
                <Scratch1 className={cn(css.scratch, css.first)} />
                Revoice
              </span>{' '}
              your videos
            </h1>
            <p className={css.desc}>
              from Youtube channel to English and other languages ​​to increase your audience and advertising revenue
              at least two times
            </p>
            <div className={css.controls}>
              <Button1 variant="primary" className={css.button} href="#contact">
                Get started for Free
              </Button1>
              <MyLink to="#how" className={css.link}>
                How it works?
              </MyLink>
            </div>
          </div>
          <div className={css.rightColumn}>
            <div className={css.introVideo}>
              <div className={css.videoPlace} style={{ paddingTop: video.ratio * 100 + '%' }}>
                <YoutubeVideo video={video} lang={activeLang} autoplay={autoplay} />
              </div>
              <div className={css.langs}>
                <div className={css.srcLang}>
                  <ArrowLeftDown className={css.arrowLeftDown} />
                  <FlagButton
                    lang={video.srcLang}
                    active={activeLang === video.srcLang}
                    onClick={() => {
                      setActiveLang(video.srcLang)
                    }}
                  />
                  <ArrowRightDown className={css.arrowRightDown} />
                  <ArrowRight className={css.arrowRight} />
                </div>
                <div className={css.distLangs}>
                  {video.distLangs.map((lang) => (
                    <FlagButton
                      lang={lang}
                      active={activeLang === lang}
                      onClick={() => {
                        setActiveLang(lang)
                      }}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const HowSectionItem = ({ item, i }: { item: { type: string; desc: string }; i: number }) => {
  if (item.type === 'link') {
    return (
      <MyLink to="#contact" className={cn(css.item, css[`type-${item.type}`])}>
        {item.desc}
      </MyLink>
    )
  } else {
    return (
      <div className={cn(css.item, css[`type-${item.type}`])}>
        <div className={css.itemHeader}>
          <div className={css.itemNumber}>{i + 1}</div>
          <div className={css.itemTitle}>
            {item.type === 'you' ? 'Your job' : item.type === 'ai' ? 'AI job' : 'Profit'}
          </div>
        </div>
        <div className={css.itemDesc}>{item.desc}</div>
      </div>
    )
  }
}

const HowSection = () => {
  const items = [
    { type: 'you', desc: 'Upload the original video' },
    { type: 'you', desc: 'Specify the original language of the audio track' },
    { type: 'you', desc: 'Specify the languages ​​into which the video needs to be translated' },
    { type: 'ai', desc: 'The program extracts the original audio track' },
    { type: 'ai', desc: 'Artificial intelligence translates the audio into text with time stamps' },
    {
      type: 'ai',
      desc: 'Artificial intelligence translates the text into other languages ​​while preserving the time stamps',
    },
    { type: 'ai', desc: 'Artificial intelligence voices the received texts in new languages' },
    { type: 'ai', desc: 'Upload the original video' },
    {
      type: 'you',
      desc: 'You can download videos with tracks in all languages, the audio tracks themselves separately, or even subtitles separately',
    },
    { type: 'you', desc: 'Adds audio tracks to your videos on YouTube' },
    { type: 'profit', desc: 'You get at least a 2-fold increase in your audience' },
    { type: 'link', desc: 'Let’s try?' },
  ]

  return (
    <div className={css.how} id="how">
      <div className={css.sectionWithPadding}>
        <h2 className={css.sectionTitle}>
          How{' '}
          <span className={css.withScratch}>
            <Scratch3 className={cn(css.scratch, css.third)} />
            it works?
          </span>
        </h2>
        <div className={css.items}>
          {items.map((item, i) => (
            <HowSectionItem item={item} i={i} key={i} />
          ))}
        </div>
      </div>
    </div>
  )
}

const SamplesSectionItem = ({
  item,
}: {
  item: { videoTitle: string; ownerTitle: string; ownerUrl?: string; video: Video }
}) => {
  const video = normalizeVideo(item.video)
  const [activeLang, setActiveLang1] = useState(video.distLangs[0])
  const [autoplay, setAutoplay] = useState(false)
  const setActiveLang = (lang: Lang) => {
    setActiveLang1(lang)
    setAutoplay(true)
  }
  return (
    <div className={css.item}>
      <div className={css.videoPlace} style={{ paddingTop: video.ratio * 100 + '%' }}>
        <YoutubeVideo video={video} lang={activeLang} autoplay={autoplay} />
      </div>
      <div className={css.langs}>
        <div className={css.srcLang}>
          <FlagButton
            lang={video.srcLang}
            active={activeLang === video.srcLang}
            onClick={() => {
              setActiveLang(video.srcLang)
            }}
          />
          <ArrowRight className={css.arrowRight} />
        </div>
        <div className={css.distLangs}>
          {video.distLangs.map((lang) => (
            <FlagButton
              lang={lang}
              active={activeLang === lang}
              onClick={() => {
                setActiveLang(lang)
              }}
            />
          ))}
        </div>
      </div>
      <div className={css.itemTitle}>{item.videoTitle}</div>
      <div className={css.itemOwner}>
        For{' '}
        {item.ownerUrl ? (
          <a href={item.ownerUrl} className={css.itemOwnerMyLink} target="_blank">
            {item.ownerTitle}
          </a>
        ) : (
          <>{item.ownerTitle}</>
        )}
      </div>
    </div>
  )
}

const SamplesSection = () => {
  const items = [
    ..._.times(33, (i) => ({
      videoTitle: `The Future of AI: What to Expect in the Next Decade ${i}`,
      ownerTitle: 'TEDx Talks',
      ownerUrl: 'https://www.google.com',
      video: {
        srcLang: 'en',
        width: 1280,
        height: 720,
        versions: [
          { youtubeId: 'ja1QQLS8788', lang: 'en' },
          { youtubeId: 'eX7XeLQ3p2U', lang: 'es' },
          { youtubeId: 'eX7XeLQ3p2U', lang: 'pt' },
          { youtubeId: 'eX7XeLQ3p2U', lang: 'ru' },
          { youtubeId: 'eX7XeLQ3p2U', lang: 'it' },
          { youtubeId: 'eX7XeLQ3p2U', lang: 'de' },
          { youtubeId: 'eX7XeLQ3p2U', lang: 'tr' },
        ],
      } as Video,
    })),
  ]
  const [visibleItemsCount, setVisibleItemsCount] = useState(6)
  const visibleItems = useMemo(() => items.slice(0, visibleItemsCount), [visibleItemsCount])
  const hasMore = useMemo(() => visibleItemsCount < items.length, [visibleItemsCount])
  const showMore = () => {
    setVisibleItemsCount(visibleItemsCount + 3)
  }
  return (
    <div className={css.samples} id="samples">
      <div className={css.sectionWithPadding}>
        <h2 className={css.sectionTitle}>
          Revoice{' '}
          <span className={css.withScratch}>
            <Scratch2 className={cn(css.scratch, css.second)} />
            samples
          </span>
        </h2>
        <div className={css.items}>
          {visibleItems.map((item, i) => (
            <SamplesSectionItem item={item} key={i} />
          ))}
        </div>
        {hasMore && (
          <div className={css.controls}>
            <Button1 variant="dashed" onClick={showMore} className={css.button}>
              Show more
            </Button1>
          </div>
        )}
      </div>
    </div>
  )
}

const PriceSection = () => {
  return (
    <div className={css.price} id="price">
      <div className={css.sectionWithPadding}>
        <div className={css.panel}>
          <h2 className={css.sectionTitle}>
            About{' '}
            <span className={css.withCircle}>
              <Circle className={css.circle} />
              $30
            </span>
            <br />
            per hour of video
          </h2>
          <p className={css.desc}>And even cheaper if you re-voice one video into several languages</p>
        </div>
      </div>
    </div>
  )
}

const ContactSection = () => {
  const contactUs = trpc.contactUs.useMutation()
  const formy = useFormy({
    initialValues: {
      contact: '',
      message: '',
    },
    validationSchema: zContactUsEndpointInput,
    onSubmit: async ({ valuesInput }) => {
      await contactUs.mutateAsync({
        ...valuesInput,
      })
    },
    successMessage: 'Your request has been sent. Thanks!',
    resetOnSuccess: true,
  })
  return (
    <div className={css.contact} id="contact">
      <div className={css.sectionWithPadding}>
        <h2 className={css.sectionTitle}>
          Get started{' '}
          <span className={css.withScratch}>
            <Scratch4 className={cn(css.scratch, css.fourth)} />
            for Free
          </span>
        </h2>
        <p className={css.desc}>
          Your personal manager will re-voice part of any video from your YouTube channel for free, so you can make sure
          that shoot'n'then will be useful for you!
        </p>
        <div className={css.panel}>
          <h3 className={css.panelTitle}>Leave a request to contact your personal manager</h3>
          <form className={css.formItems} {...formy.formProps}>
            <Inputy1 placeholder="Email, Telegram or Phone" {...formy.getFieldProps('contact')} />
            <Textary1 placeholder="Message" {...formy.getFieldProps('message')} />
            <Button1 {...formy.buttonProps} variant="primary" type="submit">
              Contact me
            </Button1>
          </form>
        </div>
      </div>
    </div>
  )
}

export const PromoPage = withPageWrapper({
  isTitleExact: true,
  title: `shoot'n'then`,
})(() => {
  return (
    <div className={css.page}>
      <Header />
      <IntroSection />
      <HowSection />
      <SamplesSection />
      <PriceSection />
      <ContactSection />
      <div className={css.footer}>
        <LogoGray className={css.logo} />
      </div>
    </div>
  )
})
