import { type Lang, langOptions, zLangRequired } from '@/general/src/lang/utils.shared.js'
import type { ClientProjectForUser } from '@/general/src/project/utils.server.js'
import { Selecty, useFormy } from '@/webapp/src/lib/formy.js'
import { Block, Button, Buttons, FormItems, Modal, Segment } from '@/webapp/src/lib/uninty.components.js'
import { useRef, useState } from 'react'
import { z } from 'zod'
import axios, { isAxiosError } from 'axios'
import { useAppContext } from '@/webapp/src/lib/ctx.js'

type Step = 'none' | 'video' | 'audio' | 'wrong' | 'uploading'
type SetStep = (step: Step) => void
type Progress = number // 0...1
type SetProgress = (progress: number) => void

const SomethingWentWrongModal = ({ setStep, opened }: { setStep: SetStep; opened: boolean }) => {
  return (
    <Modal
      opened={opened}
      $style={{
        padding: 20,
        overlayColor: 'rgba(0, 0, 0, 0.5)',
      }}
    >
      <Segment title="Something went wrong">
        <button
          onClick={() => {
            setStep('none')
          }}
        >
          Ok
        </button>
      </Segment>
    </Modal>
  )
}

const UploadVideoModal = ({
  project,
  file,
  step,
  setStep,
  progress,
  setProgress,
}: {
  project: ClientProjectForUser
  file: File | null
  step: Step
  setStep: SetStep
  progress: Progress
  setProgress: SetProgress
}) => {
  const ctx = useAppContext()
  const opened = step === 'video'
  const formy = useFormy({
    initialValues: {
      projectId: project.id,
      lang: '' as Lang,
    },
    validationSchema: z.object({
      projectId: z.string(),
      lang: zLangRequired,
    }),
    resetOnSuccess: true,
    onSubmit: async ({ valuesInput }) => {
      if (!file) {
        throw new Error('File is missing')
      }
      setStep('uploading')
      const formData = new FormData()
      const url = new URL(`${ctx.env.VITE_BACKEND_URL}/api/artifact/upload-video`)
      url.searchParams.append('projectId', valuesInput.projectId)
      url.searchParams.append('langs', valuesInput.lang)
      url.searchParams.append('fileOriginalName', file.name)
      url.searchParams.append('fileMimeType', file.type)
      url.searchParams.append('fileSizeBytes', file.size.toString())
      formData.append('file', file)

      try {
        await axios.post(url.toString(), formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (progressEvent) => {
            if (progressEvent.total) {
              const percentCompleted = progressEvent.loaded / progressEvent.total
              setProgress(percentCompleted > 0.99 ? 0.99 : percentCompleted)
            }
          },
        })
        setStep('none')
        setProgress(0)
      } catch (error: any) {
        // eslint-disable-next-line no-console
        console.error(error)
        const message = (isAxiosError(error) ? error.response?.data?.message : error.message) || 'Unknown error'
        alert(message)
        setStep('none')
        setProgress(0)
      }
    },
  })
  if (!file) {
    return <SomethingWentWrongModal setStep={setStep} opened={opened} />
  }
  return (
    <Modal
      opened={opened}
      $style={{
        padding: 20,
      }}
    >
      <Segment title="Uploading Video">
        <FormItems as="form" {...formy.formProps}>
          <Selecty label="Language" options={langOptions} {...formy.getFieldProps('lang')} />
          <Buttons>
            <Button {...formy.buttonProps} type="submit">
              Upload
            </Button>
            <Button
              type="button"
              onClick={() => {
                setStep('none')
              }}
            >
              Cancel
            </Button>
          </Buttons>
        </FormItems>
      </Segment>
    </Modal>
  )
}

const UploadAudioModal = ({
  project,
  file,
  step,
  setStep,
  progress,
  setProgress,
}: {
  project: ClientProjectForUser
  step: Step
  file: File | null
  setStep: SetStep
  progress: Progress
  setProgress: SetProgress
}) => {
  const ctx = useAppContext()
  const opened = step === 'audio'
  const formy = useFormy({
    initialValues: {
      projectId: project.id,
      lang: '' as Lang,
    },
    validationSchema: z.object({
      projectId: z.string(),
      lang: zLangRequired,
    }),
    resetOnSuccess: true,
    onSubmit: async ({ valuesInput }) => {
      if (!file) {
        throw new Error('File is missing')
      }
      setStep('uploading')
      const formData = new FormData()
      const url = new URL(`${ctx.env.VITE_BACKEND_URL}/api/artifact/upload-audio`)
      url.searchParams.append('projectId', valuesInput.projectId)
      url.searchParams.append('lang', valuesInput.lang)
      url.searchParams.append('fileOriginalName', file.name)
      url.searchParams.append('fileMimeType', file.type)
      url.searchParams.append('fileSizeBytes', file.size.toString())
      formData.append('file', file)

      try {
        await axios.post(url.toString(), formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (progressEvent) => {
            if (progressEvent.total) {
              const percentCompleted = progressEvent.loaded / progressEvent.total
              setProgress(percentCompleted > 0.99 ? 0.99 : percentCompleted)
            }
          },
        })
        setStep('none')
        setProgress(0)
      } catch (error: any) {
        // eslint-disable-next-line no-console
        console.error(error)
        const message = (isAxiosError(error) ? error.response?.data?.message : error.message) || 'Unknown error'
        alert(message)
        setStep('none')
        setProgress(0)
      }
    },
  })
  if (!file) {
    return <SomethingWentWrongModal setStep={setStep} opened={opened} />
  }
  return (
    <Modal
      opened={opened}
      $style={{
        padding: 20,
      }}
    >
      <Segment title="Uploading Audio">
        <FormItems as="form" {...formy.formProps}>
          <Selecty label="Language" options={langOptions} {...formy.getFieldProps('lang')} />
          <Buttons>
            <Button {...formy.buttonProps} type="submit">
              Upload
            </Button>
            <Button
              type="button"
              onClick={() => {
                setStep('none')
              }}
            >
              Cancel
            </Button>
          </Buttons>
        </FormItems>
      </Segment>
    </Modal>
  )
}

const UploadWrongModal = ({ step, setStep }: { step: Step; setStep: SetStep }) => {
  return (
    <Modal
      opened={step === 'wrong'}
      $style={{
        padding: 20,
        overlayColor: 'rgba(0, 0, 0, 0.5)',
      }}
    >
      <Segment title="Wrong file format">
        <p>Allowed only mp4 or mp3</p>
        <button
          onClick={() => {
            setStep('none')
          }}
        >
          Ok
        </button>
      </Segment>
    </Modal>
  )
}

export const ArtifactUpload = ({ project }: { project: ClientProjectForUser }) => {
  const [file, setFile] = useState<File | null>(null)
  const [step, setStep] = useState<Step>('none')
  const [progress, setProgress] = useState<Progress>(0) // 0...1
  const fileInputRef = useRef<HTMLInputElement>(null)

  return (
    <Block>
      {step !== 'uploading' && (
        <>
          <input
            style={{ display: 'none' }}
            type="file"
            accept="video/mp4, audio/mp3, audio/mpeg"
            ref={fileInputRef}
            onChange={({ target: { files } }) => {
              void (async () => {
                const fileInput = fileInputRef.current
                if (!fileInput) {
                  return
                }
                const file = files?.[0]
                if (!file) {
                  return
                }
                if (['video/mp4'].includes(file.type)) {
                  setFile(file)
                  setStep('video')
                } else if (['audio/mp3', 'audio/mpeg'].includes(file.type)) {
                  setFile(file)
                  setStep('audio')
                } else {
                  setStep('wrong')
                }
                fileInputRef.current.value = ''
              })()
            }}
          />
          <Button
            type="button"
            onClick={() => {
              const fileInput = fileInputRef.current
              if (!fileInput) {
                return
              }
              fileInput.click()
            }}
          >
            Upload mp4 or mp3
          </Button>
        </>
      )}
      {step === 'uploading' && (
        <Button type="button" disabled={true}>
          Uploading {Math.round(progress * 100)}%
        </Button>
      )}
      <UploadVideoModal
        file={file}
        project={project}
        step={step}
        setStep={setStep}
        progress={progress}
        setProgress={setProgress}
      />
      <UploadAudioModal
        file={file}
        project={project}
        step={step}
        setStep={setStep}
        progress={progress}
        setProgress={setProgress}
      />
      <UploadWrongModal step={step} setStep={setStep} />
    </Block>
  )
}
