import { ArtifactUpload } from '@/general/src/artifact/components/ArtifactUpload.js'
import type { ClientProjectForUser } from '@/general/src/project/utils.server.js'
import { Block, Segment } from '@/webapp/src/lib/uninty.components.js'

export const Artifacts = ({ project }: { project: ClientProjectForUser }) => {
  return (
    <Segment title={`Atifacts`} desc={`Files of your project`} size="m">
      <Block fcnw g={20}>
        <ArtifactUpload project={project} />
      </Block>
    </Segment>
  )
}
